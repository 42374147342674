import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { name: String, count: Number }

  connect() {
    console.log("Connect to Deselect")
  }

  deselect() {
    for (let i = 0; i < this.countValue; i++) {
      const optionId = `custom_fields_${this.nameValue}_${i}`;
      const option = document.getElementById(optionId);
      if (option) {
        option.checked = false;
      }
    }

    const empty_radio = document.getElementById(`custom_fields_${this.nameValue}_hidden`);
    console.log("Selecting empty")
    empty_radio.checked = true;
  }

}

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const date = new Date();
    const offsetMinutes = date.getTimezoneOffset();

    console.log("Raw offset minutes:", offsetMinutes);

    const offsetSign = Math.sign(offsetMinutes) === 1 ? '+' : '-';
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetString = `${offsetSign}${String(offsetHours).padStart(2, '0')}:00`;

    const browserTimezoneOffset = offsetMinutes === 0 ? 'UTC±00:00' : `UTC${offsetString}`;

    const timezoneAbbreviation = this.getTimezoneAbbreviation();

    console.log("Browser timezone name:", timezoneName);
    console.log("Browser timezone offset:", browserTimezoneOffset);
    console.log("Browser timezone abbreviation:", timezoneAbbreviation);

    const select = this.element;


    for (let i = 0; i < select.options.length; i++) {
      const optionValue = select.options[i].value;


      if (
        optionValue.includes(browserTimezoneOffset) ||
        optionValue.includes(timezoneName) ||
        optionValue.includes(timezoneAbbreviation)
      ) {
        select.selectedIndex = i;
        break;
      }
    }


    if (select.slim) {
      select.slim.setSelected(select.value);
    }
  }

  getTimezoneAbbreviation() {
    const dateString = new Date().toLocaleDateString('en-US', {
      timeZoneName: 'short'
    });

    return dateString.split(' ')[1];
  }
}
import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static values = {
    content: String,
    disabled: { type: Boolean, default: false }
  }

  connect() {
    this.initializeTooltip();
  }

  disconnect() {
    if (this.tippy) {
      this.tippy.destroy();
    }
  }

  initializeTooltip() {
    let options = {};

    if (this.data.get('appendTo')) {
      options['appendTo'] = this.data.get('appendTo');
    }

    if (this.hasContentValue) {
      options['content'] = this.contentValue;
    }

    this.tippy = tippy(this.element, options);
  }

  updateTooltipState() {
    const disabledValue = this.element.getAttribute("data-tooltip-disabled-value") === "true";
    if (disabledValue) {
      this.tippy.disable();
    } else {
      this.tippy.enable();
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  connect() {
  }

  change(event) {
    let brand_id = event.target.selectedOptions[0].value;
    
    console.log("Selected Brand ID: ", brand_id);
    
    if (brand_id) {
      get(`/settings/brand/brand_choice?brand_id=${brand_id}`, {
        responseKind: "turbo-stream"
      });
    } else {
      console.log("No brand selected.");
    }
  }


}

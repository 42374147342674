import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fieldTypeSelect", "precisionInput", "optionsInput", "radioDisplaySelect", "rangeFields", "widthDefaultInput", "widthPortalInput", "rangeTypeInput"];

  static values = { widthDefault: Number, widthPortal: Number };

  initialize() {
    if (this.widthDefaultInputTarget && this.widthDefaultInputTarget.value > 0) {
      this.widthDefaultValue = this.widthDefaultInputTarget.value;
    }
    if (this.widthPortalInputTarget && this.widthPortalInputTarget.value > 0) {
      this.widthPortalValue = this.widthPortalInputTarget.value;
    }
  }

  connect() {
    const fieldType = this.fieldTypeSelectTarget.value;

    this.checkFieldType(fieldType);
  }

  disconnect() {

  }

  fieldTypeChange(event) {
    this.checkFieldType(event.target.value);
  }

  checkFieldType(value) {
    const precisionInputWrapper = this.precisionInputTarget.parentElement;
    const optionsInputWrapper = this.optionsInputTarget.parentElement;
    const radioDisplaySelectWrapper = this.radioDisplaySelectTarget.parentElement;
    const rangeFieldsWrapper = this.rangeFieldsTarget.parentElement;
    const rangeTypeInputWrapper = this.rangeTypeInputTarget.parentElement;

    if (value === "number") {
      precisionInputWrapper.classList.remove("hidden");
    } else {
      precisionInputWrapper.classList.add("hidden");
    }

    if (value === "multi_select" || value === "radio") {
      optionsInputWrapper.classList.remove("hidden");
    } else {
      optionsInputWrapper.classList.add("hidden");
    }

    if (value === "radio") {
      radioDisplaySelectWrapper.classList.remove("hidden");
    } else {
      radioDisplaySelectWrapper.classList.add("hidden");
    }

    if (value === "range") {
      rangeFieldsWrapper.classList.remove("hidden");
      rangeTypeInputWrapper.classList.remove("hidden");
      if (this.widthDefaultInputTarget && this.widthDefaultValue === 0) {
        this.widthDefaultInputTarget.value = 3;
      }
      if (this.widthPortalInputTarget && this.widthPortalValue === 0) {
        this.widthPortalInputTarget.value = 1;
      }
    } else {
      rangeFieldsWrapper.classList.add("hidden");
      rangeTypeInputWrapper.classList.add("hidden");
      if (this.widthDefaultInputTarget && this.widthDefaultInputTarget.value > 0) {
        this.widthDefaultInputTarget.value = this.widthDefaultValue;
      }
      if (this.widthPortalInputTarget && this.widthPortalInputTarget.value > 0) {
        this.widthPortalInputTarget.value = this.widthPortalValue;
      }
    }
  }
}

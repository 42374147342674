import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element"]

  connect() {
    this.checkTruncation();
    window.addEventListener("resize", this.checkTruncation.bind(this));
  }

  disconnect() {
    window.removeEventListener("resize", this.checkTruncation.bind(this));
  }

  checkTruncation() {
    const element = this.element;
    const isTruncated = element.scrollWidth > element.clientWidth;
    const tooltipController = this.application.getControllerForElementAndIdentifier(element, "tooltip");

    if (isTruncated) {
      element.setAttribute("data-tooltip-disabled-value", "false");
      if (tooltipController) {
        tooltipController.updateTooltipState();
      }
    } else {
      element.setAttribute("data-tooltip-disabled-value", "true");
      if (tooltipController) {
        tooltipController.updateTooltipState();
      }
    }
  }
}

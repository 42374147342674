import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["spinner"];

  connect() {
    window.addEventListener("focus", this.hideSpinner.bind(this));
  }

  disconnect() {
    window.removeEventListener("focus", this.hideSpinner.bind(this));
  }

  download(event) {
    this.spinnerTarget.classList.remove("hidden");
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("hidden");
  }
}

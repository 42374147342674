import { Controller } from "@hotwired/stimulus";
import Flatpickr from "flatpickr";

// Connects to data-controller="flatpickrui"
export default class extends Controller {
  connect() {
    this.color = this.element.getAttribute("data-calendar-border-color") || "#ED5E5E";
    this.setCalendarBorderColor();

    this.dateFormat = this.element.getAttribute("data-date-format") || "Y-m-d";
    this.minDate = this.element.getAttribute("data-min-date") || null;
    this.defaultDate = this.element.getAttribute("data-default-date") || null;
    this.altInputClass = this.element.getAttribute("data-alt-input") || null;
    this.enable = this.element.getAttribute("data-enable") ? JSON.parse(this.element.getAttribute("data-enable")) : [];
    this.inline = this.element.getAttribute("data-enable-inline") === "true";
    this.enableDate = this.element.getAttribute("data-enable-date") === "true";
    this.enableTime = this.element.getAttribute("data-enable-time") === "true";
    this.time_24hr = this.element.getAttribute("data-time-24hr") === "true";
    this.altFormat = this.element.getAttribute("data-alt-format");

    let flatpickrOptions = {
      allowInput: true,
      altInput: true,
      enableTime: this.enableTime,
      dateFormat: this.dateFormat,
      enableDate: this.enableDate,
      minDate: this.minDate,
      time_24hr: this.time_24hr,
      inline: this.inline,
      onChange: this.change.bind(this),
    };

    if (this.altInputClass) {
      flatpickrOptions.altInputClass = this.altInputClass;
    }

    if (this.defaultDate) {
      console.log(this.defaultDate);
      flatpickrOptions.defaultDate = this.defaultDate;
    }

    if (this.altFormat) {
      flatpickrOptions.altFormat = this.altFormat;
    }

    if (this.enable.length > 0) {
      flatpickrOptions.enable = this.enable;
      flatpickrOptions.defaultDate = this.enable[0];
      this.filterSchedules(this.enable[0]);
    }

    Flatpickr(this.element, flatpickrOptions);
  }

  change(selectedDates, dateStr) {
    this.filterSchedules(dateStr);
  }

  setCalendarBorderColor() {
    document.documentElement.style.setProperty('--calendar-border-color', this.color);
    console.log(this.color);
  }

  filterSchedules(selectedDate) {
    const schedules = document.querySelectorAll('.schedule-item');

    schedules.forEach((schedule) => {
      const scheduleDate = schedule.dataset.date;
      console.log(`Selected date: ${selectedDate}, Schedule date: ${scheduleDate}`);

      if (scheduleDate === selectedDate) {
        schedule.style.display = 'block';
      } else {
        schedule.style.display = 'none';
      }
    });
  }
}

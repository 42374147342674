import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.mountScrollable();
  }

  mountScrollable() {
    const children = this.element.querySelectorAll('.scrollable-container > div:not(.scrollable-shadow)');
    const nodes = [children[0], children[children.length - 1]];
    const shadowNodes = this.element.querySelectorAll('.scrollable-shadow');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const shadowNode = entry.target === nodes[0] ? shadowNodes[0] : shadowNodes[1];
        shadowNode.classList.toggle('show-scrollable-shadow', entry.intersectionRatio < 0.9);
      });
    },
      {
        threshold: [0, 0.9, 1],
      }
    );

    nodes.forEach(item => observer.observe(item));
  }
}

import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="close-dialog"
export default class extends Controller {
  connect() {
    this.element.addEventListener('turbo:submit-end', (event) => {
      if (event.detail.success) {
        this.element.close();
      }
    });
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String, method: String }

  initialize() {
    this.methodValue = "GET";
  }

  async fetchJson(callback) {
    const response = await fetch(this.urlValue, {
      method: this.methodValue,
      headers: {
        "Accept": "application/json",
        "X-CSRF-Token": document.querySelector("[name=csrf-token]").content, // Include CSRF token
      },
    });

    if (response.ok) {
      const data = await response.json();

      callback(data);
    } else {
      console.error(`Failed to fetch content from ${this.urlValue}`);
    }
  }

  // expect json: { content: "" }
  async copyCollaborateLink(event) {
    event.preventDefault();

    this.fetchJson((res) => {
      navigator.clipboard.writeText(res.content).then(() => {
        this.element.innerText = "Copied!";
        setTimeout(() => {
          this.element.innerText = 'Collaborate Link';
          window.location.reload();
        }, 2000);
        console.log("Text successfully copied to clipboard!");
      }).catch(err => {
        console.error("Failed to copy text: ", err);
      });
    });
  }

  // other actions
}
